export const config = {
  appId: '2ad93e71-4204-47ff-a42a-567bb908a7c2',
  // local
  // redirectUrl: 'http://localhost:3000',
  //test
  redirectUrl: 'https://med-dashboard.myemergencydr.com.au',
  scopes: ['user.read'],
  authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTHORITY}`,
  IS_PROD: process.env.REACT_APP_ENVIRONMENT === 'production',
};
